import React, { useRef, useState } from "react";
import styles from "./ChatBubble.module.css";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

const ChatBubble = ({
  messsage,
  isSender,
  timestamp,
  id,
  isSelectingMessages,
  getSelectedMessageId,
  handleUpdateMessage,
}) => {
  const time = moment(timestamp).format("hh:mmA");
  const checkRef = useRef();
  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState(false);
  const [inputText, setInputText] = useState(messsage);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkboxStyle = {
    color: "#000",
    "&.Mui-checked": { color: "#ED1E24" },
    marginRight: "0px",
    fontFamily: "Montserrat",
    "&.Mui-disabled": {
      color: "#959595",
    },
  };

  console.log("theChatBubble", messsage.split("\n"));
  return (
    <div className={styles.wrapper}>
      <div
        className={`${isSender ? styles.sender : styles.main} ${
          isEdit && styles.editing
        }`}
      >
        {isSender && isEdit && (
          <div>
            <TextField
              className={styles.text}
              id="standard-textarea"
              variant="standard"
              multiline
              maxRows={14}
              fullWidth
              value={inputText || ""}
              onChange={(e) => setInputText(e.target.value)}
              autoFocus
              placeholder={t("liveChat.typeMessage")}
            />
          </div>
        )}

        {!isEdit && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {messsage.split("\n")?.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        )}

        {!isEdit && (
          <div className={styles.date_wrap}>
            <p>{time}</p>
            {isSender && (
              <>
                <KeyboardArrowDownIcon
                  onClick={handleClick}
                  style={{ marginLeft: "auto", cursor: "pointer" }}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
                      fontFamily: "Montserrat",
                      borderRadius: '.35rem',
                      padding: 0
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left', // Aligns the menu to the left
                  }}
                >
                  <MenuItem
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: '0.875rem',
                      color: "#000000",
                      minHeight: "auto",
                      backgroundColor: 'white',
                      padding: '0 0.5rem',
                      '&:focus':{
                          backgroundColor:'white'
                        }
                    }}
                    onClick={() => {
                      setIsEdit(true);
                      handleClose();
                    }}
                  >
                    Edit
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        )}

        {isEdit && (
          <div>
            <CloseIcon
              onClick={() => setIsEdit(false)}
              style={{
                cursor: "pointer",
                color: "red",
              }}
            />
            <CheckIcon
              style={{
                cursor: "pointer",
                color: "green",
              }}
              onClick={()=> {
                if(inputText?.trim()?.length > 0){
                  handleUpdateMessage(id, inputText?.trim());
                  setIsEdit(false);
                }
              }}
            />
          </div>
        )}
      </div>

      {isSelectingMessages && isSender && (
        <Checkbox
          className={styles.checkbox}
          onChange={() => getSelectedMessageId(id, checkRef.current.checked)}
          inputRef={checkRef}
          sx={checkboxStyle}
        />
      )}
    </div>
  );
};

export default ChatBubble;
