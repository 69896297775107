import React from "react";
import Modal from "../../../../../components/commons/Modal";
import EditSkills from "./Modals/EditSkills";
import AddSkills from "./Modals/AddSkills";

import styles from "../../../Profile.module.css";
import IconTitle from "../../../../../components/commons/IconTitle";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Add } from "@material-ui/icons";

class EndorsementsFeed extends React.Component {
  state = {
    modalTitle: "",
    add: false,
    edit: false,
    endIdx: 4,

    seeall: false,
  };

  getRandomNumber() {
    return Math.floor(Math.random() * (999 - 100 + 1) + 100);
  }

  changeEdit() {
    this.setState({ edit: false, add: false });
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px 0px 0px",
          }}
        >
          <IconTitle title={t("profile.skillsAndEndorsements")} img="/Image/apps/profile-resume-edit-view/intrests.svg" />
          <div style={{
            display:'flex',
            alignItems: 'center',
            gap: '1.5rem'
          }}>
          <div
              style={{
                background: "#E5E5E5 0% 0% no-repeat padding-box",
                borderRadius: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: 'pointer'
              }}
              onClick={() => {
                this.setState({ add: true, modalTitle: t("profile.addSkills")});
                document.body.style.overflow = "hidden";
              }}
            >
              <Add color="#E5E5E5" />
            </div>

          <div
            onClick={() => {
              this.setState({ edit: true, modalTitle: t("profile.EditSkills") });
              document.body.style.overflow = "hidden";
            }}
          >
            <img src="/Image/Chalkmate_Edit.svg" style={{ height: 20, cursor: "pointer" }} />
          </div>
          </div>
        </div>
        <br />
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div
            style={{
              padding: this.props.skillContent.length <= 4 ? 0 : 20,
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              borderBottom: this.props.skillContent.length <= 4 ? "none" : "1px solid #5555551A",
            }}
          >
            {this.props.skillContent?.length === 0 && <div 
             style={{
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              <p className={styles.edit_info}>{t("profile.addYourSkills")}</p>
              <button
                className={styles.btn}
                style={{
                  border: "2px solid #ED1E24",
                  padding: "12px 20px",
                  borderRadius: 40,
                  cursor: "pointer",
                background:'linear-gradient(180.00deg, rgb(255, 236, 236) 0.011%,rgb(255, 246, 246) 93.847%)'
                }}
                onClick={() => {
                  this.setState({ add: true, modalTitle: t("profile.addSkills")});
                  document.body.style.overflow = "hidden";
                }}
              >
                <p className={styles.btn_text}>+ {t("profile.addSkills")}</p>
              </button>
            </div>}

            <div>
              <div style={{ marginTop: 10, display: "flex", flexWrap: "wrap" }}>
                {this.props.skillContent.slice(0, this.state.endIdx).map((item, idx) => {
                  return (
                    <div
                      className={styles.wrap_100}
                      key={idx}
                      // style={{ width: "50%" }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          <img src={item.upload_img} height="50px" width="50px" style={{ borderRadius: 40, margin: 10 }} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <p className={styles.section_title}>{item.title}</p>
                          <p className={styles.followers}>{this.getRandomNumber()} {t("common.followers")}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {this.props.skillContent.length <= 4 ? null : (
            <div
              style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 10 }}
              onClick={() => {
                if (this.state.endIdx > 4) {
                  this.setState({ endIdx: 4 });
                } else {
                  this.setState({ endIdx: this.props.skillContent.length });
                }

                if (this.state.seeall) {
                  this.setState({ seeall: false });
                } else {
                  this.setState({ seeall: true });
                }
              }}
            >
              <btn style={{ fontSize: 12, fontWeight: 600, color: "#555555", margin: 10 }}>
                {this.props.skillContent.length <= 4 ? null : this.state.seeall ? t("common.collapse") : t("common.seeAll")}
              </btn>
            </div>
          )}
        </div>

        {this.state.add ? <Modal card={<AddSkills modalTitle={this.state.modalTitle} changeEdit={this.changeEdit.bind(this)} />} /> : null}

        {this.state.edit ? <Modal card={<EditSkills modalTitle={this.state.modalTitle} changeEdit={this.changeEdit.bind(this)} />} /> : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    skillContent: state.profile?.skills || [],
  };
}

export default withTranslation()(connect(mapStateToProps, null)(EndorsementsFeed));
