import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./DeleteConfirmationModal.module.css";
import Button from "@mui/material/Button";

const DeleteConfirmationModal = (props) => { 
  const { t } = useTranslation();

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    padding: "0.5rem",
    textTransform: "none",
    fontWeight: 400,
    whiteSpace: 'nowrap',
    // marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "1.5rem",
      padding: "1.125rem 2rem",
      fontSize: "0.875rem",
      textWrap: 'none',
      fontWeight: 500,
      margin: "auto"
    },
  };


  return (
    <div className={styles.main}>
      <p className={styles.text}>{t("liveChat.areYouSure")}</p>

      <div className={styles.buttons_container}>
        <Button variant="outlined" sx={styleButtonMUI} onClick={()=>props.onDelete(true)}>
          {t("liveChat.yesDeleteForEveryone")}
        </Button>
        <Button variant="outlined" sx={styleButtonMUI} onClick={()=>props.onDelete(false)}>
          {t("liveChat.yesDeleteForMe")}
        </Button>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
