import React from "react";
import styles from "./StatsFeedAnimated.module.css";
import IconTitle from "./IconTitle";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";

const StatsFeedAnimated = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.main}>
      <IconTitle
        title={t("common.analytics")}
        img="/Image/apps/profile-resume-edit-view/experience.svg"
      />

      <div className={styles.card} style={{marginTop: '1.2rem'}}>
        <div className={styles.wrapper}>
          <img
            src="/Image/profile_page/circular-animation.gif"
            className={styles.circle}
          />
          {/* <p className={styles.count}>350+</p> */}
          <CountUp end={350} duration={3} className={styles.count} suffix="+" />
        </div>

        <p className={styles.count_label}>{t("profile.viewedYouProfile")}</p>
      </div>

      <div className={styles.card}>
        <div className={styles.wrapper}>
          <img
            src="/Image/profile_page/circular-animation.gif"
            className={styles.circle}
          />
          {/* <p className={styles.count}>150+</p> */}
          <CountUp end={200} duration={3} className={styles.count} suffix="+" />
        </div>

        <p className={styles.count_label} >{t("profile.searchAppearance")}</p>
      </div>
    </div>
  );
};

export default StatsFeedAnimated;
