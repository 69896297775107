import React from "react";
import Modal from "../../../../../components/commons/Modal";
import EditExperience from "./Modals/EditExperience";
import styles from "../../../Profile.module.css";
import IconTitle from "../../../../../components/commons/IconTitle";
import { store } from "../../../../../redux/store";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { Add } from "@material-ui/icons";

class ExperienceFeed extends React.Component {
  state = {
    edit: false,

    modalTitle: "",
    id: null,
    upload_img: "",
    title: "",
    description: "",
    start_date: null,
    end_date: null,
    employment_type: "",
    company: "",

    seeall: false,
    add: false,

    endIdx: 2,

    // expContent: []
  };

  changeEdit() {
    this.setState({ edit: false });
  }

  render() {
    const { t } = this.props;
    {
    }
    return (
      <div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems:'center'
        }}>
            <IconTitle
              title={
                store.getState()?.profile?.user?.user_type === "INDIVIDUAL"
                  ? t("profile.experience")
                  : t("profile.UniversityInstituteSummary")
              }
              img="/Image/apps/profile-resume-edit-view/experience.svg"
            />

            <div
                style={{
                  background: "#E5E5E5 0% 0% no-repeat padding-box",
                  borderRadius: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: 'pointer',
                  marginRight: '1.5rem'
                }}
                onClick={() => {
                  this.setState({
                    modalTitle:
                      store.getState()?.profile?.user?.user_type === "INDIVIDUAL"
                        ? t("profile.addExperience")
                        : t("profile.addSummary"),
                    edit: true,
                    add: true,
                    id: null,
                    upload_img: "",
                    title: "",
                    description: "",
                    start_date: null,
                    end_date: null,
                    employment_type: "",
                    company: "",
                  });
                  document.body.style.overflow = "hidden";
                }}
              >
              <Add color="#E5E5E5" />
            </div>
        </div>
        <br />
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          {this.props.expContent?.length === 0 && <div
            style={{
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <p className={styles.edit_info}>
              {store.getState()?.profile?.user?.user_type === "INDIVIDUAL"
                ? t("profile.addYourExperience")
                : t("profile.addYourUniversityInstituteSummary")}
            </p>
            <button
              className={styles.btn}
              style={{
                border: "2px solid #ED1E24",
                padding: "12px 20px",
                borderRadius: 40,
                cursor: "pointer",
                background:'linear-gradient(180.00deg, rgb(255, 236, 236) 0.011%,rgb(255, 246, 246) 93.847%)'
              }}
              onClick={() => {
                this.setState({
                  modalTitle:
                    store.getState()?.profile?.user?.user_type === "INDIVIDUAL"
                      ? t("profile.addExperience")
                      : t("profile.addSummary"),
                  edit: true,
                  add: true,
                  id: null,
                  upload_img: "",
                  title: "",
                  description: "",
                  start_date: null,
                  end_date: null,
                  employment_type: "",
                  company: "",
                });
                document.body.style.overflow = "hidden";
              }}
            >
              <p className={styles.btn_text}>
                +{" "}
                {store.getState()?.profile?.user?.user_type === "INDIVIDUAL"
                  ? t("profile.addExperience")
                  : t("profile.addSummary")}
              </p>
            </button>
          </div>}
          <div
            style={{
              // padding: this.state.expContent.length <= 2 ? 0 : 20,
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              // borderBottom: this.state.expContent.length <= 2 ? 'none' : '1px solid #5555551A'
            }}
          >
            {this.props.expContent
              .slice(0, this.state.endIdx)
              .map((item, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      borderBottom:
                        this.props.expContent.length == 1 ||
                        (this.props.expContent.length == 2 && idx == 1)
                          ? "none"
                          : "1px solid #5555551A",
                      paddingBottom: 15,
                      marginTop: idx == 0 ? 0 : 20,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <img
                          src={item.upload_img}
                          height="50px"
                          width="50px"
                          style={{ borderRadius: 40, marginRight: 15 }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p className={styles.section_title}>
                              {item.title}
                              <br />
                              <span className={styles.followers}>
                                {item.company}, {item.employment_type}
                              </span>
                            </p>
                          </div>
                        </div>
                        <p
                          className={styles.followers}
                          style={{
                            opacity: 0.7,
                            color: "#555555",
                            marginBottom: 6,
                          }}
                        >
                          {item.start_date} - {item.end_date}
                        </p>
                        <p className={styles.section_desc}>
                          {item.description}
                        </p>
                      </div>
                    </div>
                    <div>
                      <img
                        src="/Image/Chalkmate_Edit.svg"
                        style={{
                          height: 20,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({
                            modalTitle:
                              store.getState()?.profile?.user?.user_type ===
                              "INDIVIDUAL"
                                ? t("profile.editExperience")
                                : t("profile.editSummary"),
                            edit: true,
                            add: false,
                            id: item.id,
                            upload_img: item.upload_img,
                            title: item.title,
                            description: item.description,
                            start_date: item.start_date,
                            end_date: item.end_date,
                            employment_type: item.employment_type,
                            company: item.company,
                          });
                          document.body.style.overflow = "hidden";
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          {this.props.expContent.length <= 2 ? null : (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 10,
              }}
              onClick={() => {
                if (this.state.endIdx > 2) {
                  this.setState({ endIdx: 2 });
                } else {
                  this.setState({ endIdx: this.props.expContent.length });
                }

                if (this.state.seeall) {
                  this.setState({ seeall: false });
                } else {
                  this.setState({ seeall: true });
                }
              }}
            >
              <btn
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  color: "#555555",
                  margin: 10,
                }}
              >
                {this.props.expContent.length <= 2
                  ? null
                  : this.state.seeall
                  ? t("common.collapse")
                  : t("common.seeAll")}
              </btn>
            </div>
          )}
        </div>

        {this.state.edit ? (
          <Modal
            card={
              <EditExperience
                id={this.state.id}
                modalTitle={this.state.modalTitle}
                title={this.state.title}
                start_date={this.state.start_date}
                end_date={this.state.end_date}
                employment_type={this.state.employment_type}
                company={this.state.company}
                upload_img={this.state.upload_img}
                description={this.state.description}
                add={this.state.add}
                changeEdit={this.changeEdit.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    expContent: state.profile.experience || [],
  };
}

export default withTranslation()(
  connect(mapStateToProps, null)(ExperienceFeed)
);
