import React from "react";
import IconTitle from "./IconTitle";
import {store} from "../../redux/store"
import { withTranslation } from "react-i18next";
import IconBgTitle from "./IconBgTitle";
class IconSectionFeed extends React.Component {
  
  render() {
  const {t} = this.props;
  const feedTitle = [
      t("profile.about"),
      t("profile.featured"),
      t("profile.interests"),
      store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("profile.experience") : t("profile.UniversityInstituteSummary"),
      store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("profile.education") : null,
      t("profile.licensesAndCertifications"),
      store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("profile.volunteerExperience") : t("profile.VolunteerInitiative"),
      t("profile.skillsAndEndorsements"),
      /* "Recommendations", */ t("profile.achievements"),
    ];
  
  const feedTitleImages = [
      "/Image/apps/profile-resume-edit-view/about.svg",
      "/Image/Chalkmate_Featured.svg",
      "/Image/apps/profile-resume-edit-view/intrests.svg",
      "/Image/apps/profile-resume-edit-view/experience.svg",
      store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? "/Image/apps/profile-resume-edit-view/education.svg" : null,
      "/Image/certifications.svg",
      "/Image/Chalkmate_Volunteer.svg",
      "/Image/apps/profile-resume-edit-view/skills.svg",
      // "/Image/Chalkmate_Recommendation.svg",
      "/Image/Chalkmate_Achievement.svg",
    ];
  
    return (
      <div style={{marginTop: '1rem'}}>
        <div>
          {feedTitle.map((title, idx) => {
            return (
              <div
                style={{
                  marginBottom: 20,
                }}
              >
               {feedTitleImages[idx] != null ? <IconComp key={idx} title={title} icon={feedTitleImages[idx]} /> : null }
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default withTranslation()(IconSectionFeed);



const IconComp = ({ icon, title,  }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: '0.9375rem',
      }}
      >
      <img
        src={icon}
        style={{
          width: "1.5rem",
          height: "1.5rem",
          backgroundColor: '#ED1E24',
          border: "1px solid #ED1E24",
          borderRadius: '5px',
          padding: "0.375rem",
        }}
        />
      <p
        style={{
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: '1.375rem',
        }}
      >
        {title}
      </p>
    </div>
  );
};
