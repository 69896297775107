import React from "react";
import Modal from "../../../../../components/commons/Modal";
import EditVolunteer from "./Modals/EditVolunteer";
import styles from "../../../Profile.module.css";
import IconTitle from "../../../../../components/commons/IconTitle"
import {store} from "../../../../../redux/store"
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Add } from "@material-ui/icons";

class VolunteerFeed extends React.Component {
  state = {
    edit: false,

    modalTitle: "",
    id: null,
    upload_img: "",
    organisation: "",
    description: "",
    start_date: null,
    end_date: null,
    role: "",
    cause: "",

    seeall: false,
    add: false,

    endIdx: 2,

    veContent: [],
  };

  changeEdit() {
    this.setState({ edit: false });
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems:'center'
        }}>
        <IconTitle title={store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("profile.volunteerExperience") : t("profile.VolunteerInitiative")} img="/Image/Chalkmate_Volunteer.svg" />
        <div
          style={{
            background: "#E5E5E5 0% 0% no-repeat padding-box",
            borderRadius: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: 'pointer',
            marginRight: '1.5rem'
          }}
          onClick={() => {
            this.setState({
              modalTitle: t("profile.AddVolunteer"),
              edit: true,
              add: true,
              id: null,
              upload_img: "",
              organisation: "",
              description: "",
              start_date: null,
              end_date: null,
              role: "",
              cause: "",
            });
            document.body.style.overflow = "hidden";
          }}
        >
        <Add color="#E5E5E5" />
      </div>

        </div>
        <br />
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          {this.props.veContent?.length === 0 && <div
            style={{
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <p className={styles.edit_info}>{ t("profile.AddVolunteer")} {store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("profile.experience") : t("profile.initiative")}</p>
            <button
              className={styles.btn} 
              style={{
                border: "2px solid #ED1E24",
                padding: "12px 20px",
                borderRadius: 40,
                cursor: "pointer",
                background:'linear-gradient(180.00deg, rgb(255, 236, 236) 0.011%,rgb(255, 246, 246) 93.847%)'
              }}
              onClick={() => {
                this.setState({
                  modalTitle: t("profile.AddVolunteer"),
                  edit: true,
                  add: true,
                  id: null,
                  upload_img: "",
                  organisation: "",
                  description: "",
                  start_date: null,
                  end_date: null,
                  role: "",
                  cause: "",
                });
                document.body.style.overflow = "hidden";
              }}
            >
              <p className={styles.btn_text}>+ {t("profile.AddVolunteer")} {store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("profile.experience") : t("profile.initiative")}</p>
            </button>
          </div>}
          <div
            style={{
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            {this.props.veContent.slice(0, this.state.endIdx).map((item, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    borderBottom: this.props.veContent.length == 1 || (this.props.veContent.length == 2 && idx == 1) ? "none" : "1px solid #5555551A",
                    paddingBottom: 15,
                    marginTop: idx == 0 ? 0 : 20,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <img src={item.upload_img} height="50px" width="50px" style={{ borderRadius: 40, marginRight: 15 }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <p className={styles.section_title}>
                            {item.role}
                            <br />
                            <span className={styles.followers}>{item.organisation}</span>
                          </p>
                        </div>
                      </div>
                      <p className={styles.followers} style={{ opacity: 0.7, color: "#555555", marginBottom: 6 }}>
                        {item.start_date} - {item.end_date}
                      </p>
                      <p className={styles.section_desc}>{item.description}</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/Image/Chalkmate_Edit.svg"
                      style={{
                        height: 20,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          modalTitle: t("profile.EditVolunteer"),
                          edit: true,
                          add: false,
                          id: item.id,
                          upload_img: item.upload_img,
                          organisation: item.organisation,
                          description: item.description,
                          start_date: item.start_date,
                          end_date: item.end_date,
                          role: item.role,
                          cause: item.cause,
                        });
                        document.body.style.overflow = "hidden";
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {this.props.veContent.length <= 2 ? null : (
            <div
              style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 10 }}
              onClick={() => {
                if (this.state.endIdx > 2) {
                  this.setState({ endIdx: 2 });
                } else {
                  this.setState({ endIdx: this.props.veContent.length });
                }

                if (this.state.seeall) {
                  this.setState({ seeall: false });
                } else {
                  this.setState({ seeall: true });
                }
              }}
            >
              <btn style={{ fontSize: 12, fontWeight: 600, color: "#555555", margin: 10 }}>
                {this.props.veContent.length <= 2 ? null : this.state.seeall ?  t("common.collapse") : t("common.seeAll")}
              </btn>
            </div>
          )}
        </div>
        {this.state.edit ? (
          <Modal
            card={
              <EditVolunteer
                id={this.state.id}
                modalTitle={this.state.modalTitle}
                upload_img={this.state.upload_img}
                organisation={this.state.organisation}
                description={this.state.description}
                start_date={this.state.start_date}
                end_date={this.state.end_date}
                role={this.state.role}
                cause={this.state.cause}
                add={this.state.add}
                changeEdit={this.changeEdit.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    veContent: state.profile?.volunteer || [],
  };
}

export default withTranslation()(connect(mapStateToProps, null)(VolunteerFeed));
