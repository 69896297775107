import React from "react";
import Modal from "../../../../../components/commons/Modal";
import AddLanguage from "./Modals/AddLanguage";
import IconTitle from "../../../../../components/commons/IconTitle";

import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AddLanguageModal from "./AddLanguageModal";

let colors = [
  "#cef4f7",
  "#d1cdec",
  "#fed3da",
  "#fef0d6",
  "#c5ebd4",
  "#c5deeb",
  "#fed6f9",
];

class Languages extends React.Component {
  state = {
    edit: false,
  };

  changeEdit(edit) {
    this.setState({ edit: edit });
  }
  randomColor = Math.floor(Math.random() * 16777215).toString(16);
  bgcolor = [
    "#9C27B0",
    "#ED1E24",
    "#FFC107",
    "#0C1B7A",
    "#6564db",
    "#23e6e8",
    "#236ae8",
    "#ffaf02",
    "#fd0275",
  ];

  render() {
    const { t } = this.props;
    return (
      <div
        id="Tags"
        style={{
          scrollMargin: "120px 0px 0px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "98%",
          }}
        >
          <IconTitle
            title={t("profile.languagesKnown")}
            img="/Image/apps/profile-resume-edit-view/language.svg"
          />
          <img
            src="/Image/Chalkmate_Edit.svg"
            style={{
              height: 20,
              cursor: "pointer",
            }}
            onClick={() => {
              this.setState({ edit: true });
              document.body.style.overflow = "hidden";
            }}
          />
        </div>
        <br />

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {this.props.langContent?.split(",").map((item, idx) => (
                <>
                  {item ? (
                    <li
                      key={item}
                      className={styles.tagList}
                      style={{
                        background: `${colors[idx % colors.length]}`,
                        padding: "0.5rem 1rem",
                      }}
                    >
                      {item}
                    </li>
                  ) : (
                    <p>{t("profile.pleaseAddLang")}</p>
                  )}
                </>
              ))}
            </ul>
          </div>
          {/* <div
            style={{
              cursor: "pointer",
              borderTop: "1px solid rgba(85, 85, 85, 0.353)",
              marginTop: 10,
            }}
            onClick={() => {
              this.setState({ edit: true });
              document.body.style.overflow = "hidden";
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: 12,
                textTransform: "uppercase",
                fontWeight: 600,
                color: "#555555",
                margin: 23,
              }}
            >
              {t("profile.editLanguages")}
            </p>
          </div> */}
        </div>
        {/* {this.state.edit ? <Modal card={<AddLanguage title={t("profile.editLanguages")} changeEdit={this.changeEdit.bind(this)} />} /> : null} */}
        {this.state.edit ? (
          <Modal
            card={
              <AddLanguageModal
                title={t("profile.editLanguages")}
                open={this.state.edit}
                onClose={() => this.setState({ edit: false })}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    langContent: state?.profile?.language || "",
  };
}

export default withTranslation()(connect(mapStateToProps, null)(Languages));
