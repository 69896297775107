import React, { useState, useEffect, useRef } from "react";
import styles from "./LiveChat.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// import Pusher from "pusher-js";
import debounce from "lodash.debounce";
import ChatContactListItem from "./Components/ChatContactListItem";
import Avatar from "@mui/material/Avatar";
import {
  getChatList,
  getChatMessages,
  getChatSearchResult,
  sendChatMessage,
  createChannel,
  updateChatList,
  clearChatHistory,
  deleteChatHistory,
  deleteSelectedMessages,
  updateChatMessages,
  removeChannel,
  updateMessage,
} from "../../redux/actions/liveChat";
import ChatBubble from "./Components/ChatBubble";
import moment from "moment";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import ChecklistIcon from "@mui/icons-material/Checklist";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "../../components/commons/Modal";
import DeleteConfirmationModal from "./Components/DeleteConfirmationModal";
import { handleErrorMessage } from "../../redux/actions/classes";

// Pusher.logToConsole = process.env.NODE_ENV === "production" ? false : true;

// const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
//   cluster: process.env.REACT_APP_PUSHER_CLUSTER,
// });



const LiveChat = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSearchResult, setShowSearchResult] = useState(false);

  const loggedInUserID = useSelector((state) => state?.profile?.user?.user_id) || "";
  const loggedInUserToken = useSelector((state) => state?.profile?.user?.token) || "";

  const chatSocketURL = `${process.env.REACT_APP_WS_BASE_URL}${loggedInUserID}`

  const { sendMessage, lastMessage, readyState } = useWebSocket(chatSocketURL, {
    // onError: () => toast.error("Error!"),
  });

  const chatList = useSelector((state) => state?.liveChat?.chatList);
  const searchResult = useSelector((state) => state?.liveChat?.searchResult);
  let storedList = showSearchResult ? searchResult : chatList;



  const currentChatMessages = useSelector(
    (state) => state?.liveChat?.chatMessages
  );
  
  const [message, setMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  // const [contactList, setContactList] = useState();
  const [selectedContact, setSelectedContact] = useState(null);
  const [isSelectingMessages, setIsSelectingMessages] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   dispatch(getChatList());
  // }, []);

  let inputRef = useRef();
  let chatRef = useRef();
  const updateScroll = debounce(() => {
    let element = chatRef.current;
    element.scrollTop = element?.scrollHeight;
  }, 250);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   if (!showSearchResult) {
  //     if (storedList?.length > 0) {
  //       let channels = storedList?.map((user) =>
  //         pusher.subscribe(user?.channel)
  //       );

  //       channels.forEach((item, index, arr) =>
  //         item.bind("message", (data) => {
  //           dispatch(
  //             updateChatList({
  //               message: data?.message,
  //               username: data?.username,
  //               channel: item?.name,
  //               timestamp: new Date().toISOString(),
  //             })
  //           );
  //         })
  //       );
  //     }
  //   }

  //   return () =>
  //     storedList?.forEach((user) => pusher.unsubscribe(user?.channel));
  // }, [storedList?.length]);

  // useEffect(() => {
  //   if (selectedContact?.channel) {
  //     const channel = pusher.subscribe(selectedContact?.channel);
  //     channel.bind("message", (data) => {
  //       dispatch(getChatMessages(selectedContact?.channel)).then(() => {
  //         updateScroll();
  //       });
  //     });
  //   }

  //   return () => {
  //     let prevChannel = selectedContact?.channel || '';
  //     if(prevChannel){
  //       pusher.unsubscribe(prevChannel);
  //       let newChannel= pusher.subscribe(prevChannel);
  //       newChannel.bind("message", (data) => {
  //         dispatch(
  //           updateChatList({
  //             message: data?.message,
  //             username: data?.username,
  //             channel: prevChannel,
  //             timestamp: new Date().toISOString(),
  //           })
  //         );
  //       })
  //     }

  //   };
  // }, [selectedContact]);

  useEffect(() => {
    if (selectedContact?.channel) {
      dispatch(getChatMessages(selectedContact?.channel)).then(() => {
        updateScroll();
      });
    }
  }, [selectedContact])
  

  // const handleSend = (event) => {
  //   if (event.keyCode === 13) {
  //     if (event.target.value?.trim().length > 0) {
  //       dispatch(
  //         sendChatMessage(selectedContact?.channel, event.target.value?.trim())
  //       ).then(() => dispatch(getChatMessages(selectedContact?.channel)));
  //       setMessage("");
  //     }
  //   }
  // };

 

  const handleSend = (event) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      if (event.target.value?.trim().length > 0) {
        event.preventDefault();
        // dispatch(
        //   sendChatMessage(selectedContact?.channel, event.target.value?.trim())
        // ).then(() => dispatch(getChatMessages(selectedContact?.channel)));
        sendMessage(JSON.stringify(
          {
            "message": event.target.value?.trim(),
            "sender_id": loggedInUserID,
            "channel_id": selectedContact?.channel,
            "event":"chat_message"    
          }
        ))
        // dispatch(getChatMessages(selectedContact?.channel))
        setMessage("");
      }
    }
  };

  useEffect(() => {
    console.log("theLM", lastMessage?.data)
    if(lastMessage?.data) {
      let jsonObj = JSON.parse(lastMessage?.data)
      
      if(jsonObj?.event === "chat_message"){
        console.log('theChatMessage', jsonObj?.message_data?.data)
        // currentChatMessages?.push(jsonObj?.message_data?.data);
        if(jsonObj?.message_data?.data?.channel === selectedContact?.channel) {
          dispatch(updateChatMessages(jsonObj?.message_data?.data));
        }
      }

      if(jsonObj?.event === "send_dm_list") {
        console.log('theSendDMList', jsonObj?.data)
        dispatch(updateChatList(jsonObj?.data));  
      }

      if(jsonObj?.event === "delete_channel") {
        console.log('deleteChannel', jsonObj?.channel_id)
        dispatch(removeChannel(jsonObj?.channel_id))
        .then(()=>setSelectedContact(null));  
      }

      if(jsonObj?.event === "update_message") {
        console.log('updateMessage', jsonObj)
        dispatch(updateMessage(jsonObj?.message_data));
      }
    }
  }, [lastMessage]);

  // data = {channel, name, profilePic}
  const handleContactSelection = (data) => {
    if (showSearchResult === true && data?.userID && data?.channel === "") {
      dispatch(createChannel(data?.userID))
      
      .then(() => {
        dispatch(getChatList());
      });
    }

    if (data?.channel && data?.channel != selectedContact?.channel) {
      dispatch(getChatMessages(data?.channel));
      setSelectedContact({ ...data });
    }
    setShowSearchResult(false);
    inputRef.current.value = "";
  };

  const handleSearch = (event) => {
    if (event?.target?.value?.trim().length > 0) {
      setShowSearchResult(true);
      dispatch(getChatSearchResult(event?.target?.value.trim()));
    } else {
      setShowSearchResult(false);
    }
  };

  const debouncedSearch = debounce(handleSearch, 500);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearHistory = () => {
    handleClose();
    dispatch(clearChatHistory(selectedContact?.channel)).then(() =>
      setSelectedContact(null)
    );
  };

  // const handleDeleteHistory = () => {
  //   handleClose();
  //   dispatch(deleteChatHistory(selectedContact?.channel)).then(() =>
  //     setSelectedContact(null)
  //   );
  // };

  const handleDeleteHistory = () => {
    handleClose();
    sendMessage(JSON.stringify(
      {
        "event":"delete_channel",
        "channel_id": selectedContact?.channel,
        "user_id": loggedInUserID,
        "token": loggedInUserToken
    }
    ))
    setSelectedContact(null);
  };

  const handleUpdateMessage = (messageId, text) => {

    console.log("handleUpdateMessage called", {messageId, text})
    sendMessage(JSON.stringify(
      {
        "msg_id": messageId,
        "content": text,
        "event": "update_message",
        "channel_id": selectedContact?.channel,
        "user_id": loggedInUserID   
      }
    ))
  };

  const getSelectedMessageId = (id, isAdding) => {
    if (isAdding) {
      setSelectedMessages([...selectedMessages, id]);
    } else {
      let data = [...selectedMessages];
      let index = data.findIndex((item) => item === id);
      data.splice(index, 1);
      setSelectedMessages(data);
    }
  };

  const handleDeleteMessages = (isDeleteForEveryone) => {
    dispatch(deleteSelectedMessages(selectedMessages, isDeleteForEveryone))
    .then(()=>setIsSelectingMessages(false))
    
  };
  


  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  console.log("socket info: ", {
    chatSocketURL,
    loggedInUserID,
    connectionStatus,
    lastMessage,
    readyState,    
  }, 
  // JSON.parse(lastMessage?.data)
);

useEffect(()=>{
  if(connectionStatus === "Closed") {
    dispatch(handleErrorMessage(t("liveChat.connectionClosed")));
  }

},[connectionStatus])

console.log('TestRun', lastMessage?.data && JSON.parse(lastMessage?.data))


  return (
    <div className={styles.main}>
      {showModal && (
        <Modal
          card={
            <DeleteConfirmationModal
              onDelete={handleDeleteMessages}
              // onDeleteForEveryone={handleDeleteMessages}
            />
          }
          onOutsideClick={() => setShowModal(false)}
        />
      )}
      <div
        className={
          windowWidth <= 800 && selectedContact?.channel
            ? styles.hide_sidebar
            : styles.sidebar
        }
      >
        <div className={styles.sidebar_header}>
          <p>{t("liveChat.chats")}</p>
        </div>
        <div className={styles.searchbar}>
          <img
            src={
              showSearchResult
                ? "/Image/lecturePlan/arrow-left.svg"
                : "/Image/liveChat/chat_search.svg"
            }
            className={showSearchResult ? styles.back_icon : styles.search_icon}
            onClick={showSearchResult ? () => setShowSearchResult(false) : null}
          />
          {/* {showSearchResult ? (
            <p className={styles.search_label}>{t("common.searchResults")}</p>
          ) : (
            )} */}
          <input
            type="text"
            ref={inputRef}
            className={styles.search_input}
            placeholder={t("common.search")}
            // onKeyDown={handleSearch}
            // onChange={(e) => setSearchQuery(e.target.value)}
            onChange={debouncedSearch}
            // disabled={showSearchResult}
          />
          {showSearchResult && (
            <p className={styles.search_label}>{t("common.searchResults")}</p>
          )}
        </div>

        {/* ------ DM list ------- */}

        <div className={styles.contact_list}>
          {storedList?.length > 0 &&
            storedList?.map((item) => (
              <ChatContactListItem
                key={item?.id}
                name={item?.dm_info?.dm_name || item?.name}
                profilePic={item?.dm_info?.profile_pic || item?.profile_pic}
                timestamp={item?.dm_info?.msg_timestamp}
                lastMessage={item?.dm_info?.last_msg || ""}
                channel={item?.channel || item?.channel_id || ""}
                recipient={item?.recipient || item?.user_id}
                getSelectedContact={handleContactSelection}
                isSelected={
                  item?.channel === selectedContact?.channel &&
                  !showSearchResult
                }
              />
            ))}
        </div>
      </div>

      {/* -------- Right Panel ---------- */}

      {!selectedContact?.channel ? (
        <div className={styles.right_blank_panel}></div>
      ) : (
        <div className={styles.chat_window}>
          <div className={styles.chat_header}>
            {windowWidth <= 800 && (
              <img
                src="/Image/arrow_back_black.svg"
                onClick={() => setSelectedContact(null)}
                alt="show_contact_list"
                className={styles.back_arrow}
              />
            )}
            <div className={styles.current_contact}>
              <Avatar
                src={selectedContact?.profilePic || ""}
                sx={{ width: 45, height: 45 }}
              />
              <p style={{ marginLeft: "0.8rem" }}>
                {selectedContact?.name || ""}
              </p>
            </div>
            <div className={styles.menu}>
              <img
                src="/Image/assignment/more_horiz_2.svg"
                alt=""
                onClick={handleClick}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  style: {
                    // boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
                    fontFamily: "Montserrat",
                    fontSize: "0.875rem",
                    borderRadius: "1rem",
                    cursor: "pointer",
                    boxShadow: "none",
                    "&:hover": { backgroundColor: "#fff" },
                  },
                }}
              >
                <MenuItem
                  onClick={handleClearHistory}
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "0.875rem",
                    fontWeight: 300,
                  }}
                >
                  <PersonIcon sx={{ marginRight: "0.375rem" }} />
                  {t("liveChat.clearHistory")}
                </MenuItem>
                <MenuItem
                  onClick={handleDeleteHistory}
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "0.875rem",
                    fontWeight: 300,
                  }}
                >
                  <PeopleIcon sx={{ marginRight: "0.375rem" }} />
                  {t("liveChat.removeChat")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setIsSelectingMessages(true);
                  }}
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "0.875rem",
                    fontWeight: 300,
                  }}
                >
                  {/* <ChecklistIcon sx={{marginRight: '0.375rem'}}/> */}
                  <LibraryAddCheckIcon sx={{ marginRight: "0.375rem" }} />
                  {t("liveChat.selectMessages")}
                </MenuItem>
              </Menu>
            </div>
          </div>

          <div className={styles.messages} ref={chatRef}>
            {currentChatMessages?.length > 0 &&
              currentChatMessages?.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))?.map((item, index) => (
                <>
                  {moment(item?.timestamp).format("ll") !=
                    moment(currentChatMessages[index - 1]?.timestamp).format(
                      "ll"
                    ) && (
                    <p className={styles.date_split}>
                      {moment(item?.timestamp).format("ll")}
                    </p>
                  )}
                  <ChatBubble
                    key={item?.id}
                    id={item?.id}
                    messsage={item?.content || ""}
                    isSender={item?.sender === loggedInUserID || false}
                    timestamp={item?.timestamp}
                    isSelectingMessages={isSelectingMessages || false}
                    getSelectedMessageId={getSelectedMessageId}
                    handleUpdateMessage={handleUpdateMessage}
                  />
                </>
              ))}
            {currentChatMessages?.length > 0 && updateScroll()}
          </div>

          <div className={styles.textbox}>
            {isSelectingMessages ? (
              <div className={styles.delete_messages_menu}>
                <CloseIcon
                  width={24}
                  marginRight="0.375rem"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsSelectingMessages(false);
                    setSelectedMessages([]);
                  }}
                />
                <p className={styles.selected_count}>{`${
                  selectedMessages?.length || 0
                } ${t(
                  selectedMessages?.length > 1
                    ? "liveChat.messagesSelected"
                    : "liveChat.messageSelected"
                )}`}</p>
                <DeleteIcon
                  width={24}
                  marginLeft={"auto"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => setShowModal(true)}
                />
              </div>
            ) : (
              <input
                type="text"
                value={message || ""}
                className={styles.text_input}
                placeholder={t("liveChat.typeMessage")}
                onKeyDown={handleSend}
                onChange={(e) => setMessage(e.target.value)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveChat;
