import {
  GET_CHAT_LIST,
  GET_CHAT_MESSAGES,
  GET_SEARCH_RESULT,
  UPDATE_CHAT_LIST,
  DELETE_CHAT_HISTORY,
  CLEAR_CHAT_HISTORY,
  DELETE_CHAT_CHANNEL,
  DELETE_SELECTED_MESSAGES,
  UPDATE_CHAT_MESSAGES,
  UPDATE_CHAT_MESSAGE,
} from "../constants/actionTypes";
import cloneDeep from "lodash.clonedeep";

const defaultState = {
  chatList: [],
  chatMessages: [],
  searchResult: [],
};

let data = {};
let index = null;

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_CHAT_LIST:
      data = { ...state };
      data.chatList = action.payload;
      return data;

    case UPDATE_CHAT_LIST:
      data = cloneDeep(state);
      // index = data.chatList.findIndex(
      //   (item) => item?.channel === action.payload?.channel
      // );
      // if (index >= 0) {
      //   data.chatList[index].dm_info.last_msg = action.payload.message;
      //   data.chatList[index].dm_info.msg_timestamp = action.payload.timestamp;
      //   data.chatList.unshift(data?.chatList?.splice(index,1)[0]);
      // }
      data.chatList = action?.payload;
      return data;

    case GET_SEARCH_RESULT:
      data = { ...state };
      data.searchResult = action.payload;
      return data;

    case GET_CHAT_MESSAGES:
      data = { ...state };
      data.chatMessages = action.payload;
      return data;

    case UPDATE_CHAT_MESSAGES:
      data = cloneDeep(state);
      data.chatMessages.push(action.payload);
      return data;

    case UPDATE_CHAT_MESSAGE:
      data = cloneDeep(state);
      index = data.chatMessages.findIndex(item => item?.id === action.payload?.id)
      if(index >= 0) {
        data.chatMessages[index] = {...action.payload}
      }
      return data;
    
    case CLEAR_CHAT_HISTORY:
      data = cloneDeep(state);
      data.chatMessages = [];
      index = data.chatList.findIndex(item => item?.channel === action.payload)
      if(index >= 0) {
        data.chatList[index].dm_info.last_msg = "";
        data.chatList[index].dm_info.msg_timestamp = "";
      }
      return data;

    case DELETE_CHAT_CHANNEL:
      data = cloneDeep(state);
      index = data.chatList.findIndex(item => item?.channel === action.payload)
      if(index >= 0) {
        data.chatList.splice(index,1);
        data.chatMessages = [];
      }
      return data;

    case DELETE_SELECTED_MESSAGES:
      data = cloneDeep(state);
      data.chatMessages = data.chatMessages.filter((item)=>!action.payload.includes(item?.id));
      return data;

    default:
      return { ...state };
  }
};
