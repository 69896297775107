import React, { Component } from "react";
import styles from "./css/SmallChatBox.module.css";
import ChatList from "./ChatList";
import ChatHeader from "./ChatHeader";

export default class SmallChatBox extends Component {
  // state={
  //     showChat:false,
  // }
  // componentDidUpdate(){
  //     if(this.props.isChatListOpen!=this.state.showChat){
  //         this.setState({showChat:this.props.isChatListOpen})
  //     }
  // }
  openChat = (person = "Anuj Talwar") => {
    this.props.chatOpener(person);
  };
  openChatList = () => {
    this.props.toggleChatList();
  };
  render() {
    return (
      <div id="div-smallchat" className={styles.outerContainer}>
        {/* <ChatHeader  arrowClick={this.openChatList} title={"Message"} />
                {this.props.isChatListOpen && <ChatList openChat={this.openChat}/>} */}
      </div>
    );
  }
}
