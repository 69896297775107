import React, { Component } from "react";
import styles from "./css/bigBox.module.css";
import MyMessage from "./MyMessage";
import YourMessage from "./YourMessage";
import ChatHeader from "./ChatHeader";
import Add from "./images/whiteAdd.svg";
import Emoji from "./images/Emoji.png";
import GIF from "./images/GIF.png";
import Thumbup from "./images/Thumbup.png";
import { ChatContext } from "./ChatContext";

export default class BigBox extends Component {
  state = {
    showChat: true,
    message: "",
  };

  scrollToBottom = () => {
    if (this.state.showChat) this.messagesEnd.scrollIntoView();
  };

  componentDidMount() {
    this.scrollToBottom();

    if (window.innerWidth > 1100) {
      document.getElementById("div-messagebox").style.removeProperty("position");
      document.getElementById("div-messagebox").style.removeProperty("top");
      document.getElementById("div-messagebox").style.removeProperty("left");
      document.getElementById("div-messagebox").style.removeProperty("right");
      document.getElementById("div-messagebox").style.removeProperty("bottom");
      document.getElementById("div-messagebox").style.removeProperty("zIndex");

      document.getElementById("div-bigboxcontainer").style.height = "350px";
      document.getElementById("div-bigboxcontainer").style.width = "350px";
    } else if (window.innerWidth < 1100) {
      document.getElementById("div-messagebox").style.position = "absolute";
      document.getElementById("div-messagebox").style.top = "0px";
      document.getElementById("div-messagebox").style.left = "0px";
      document.getElementById("div-messagebox").style.right = "0px";
      document.getElementById("div-messagebox").style.bottom = "75px";
      document.getElementById("div-messagebox").style.zIndex = 1;

      document.getElementById("div-bigboxcontainer").style.removeProperty("height");
      document.getElementById("div-bigboxcontainer").style.height = "100%";
      document.getElementById("div-bigboxcontainer").style.width = "100%";
    }
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  hideChat = () => {
    // this.setState({
    //     showChat: !this.state.showChat
    // })
    this.props.closeChat(this.props.name);
  };
  minimizeChat = () => {
    this.setState({
      showChat: !this.state.showChat,
    });
  };

  change = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  sendMessage = (e) => {
    e.preventDefault();
    this.context.sendMessage(this.state.message);
    this.setState({ message: "" });
  };

  emojiAdder = (emoji) => {
    let mess = this.state.message;
    mess += emoji;
    this.setState({
      message: mess,
    });
  };
  render() {
    return (
      <div id="div-messagebox" style={{ marginTop: "auto" }}>
        <ChatHeader click={this.minimizeChat} title={this.props.name} cross arrowClick={this.hideChat} />
        {this.state.showChat && (
          <div id="div-bigboxcontainer" className={styles.container}>
            <div className={styles.middleContainer}>
              <p className={styles.unreadText}>
                <span className={styles.unreadSpan}>{this.context.state.messages.length} unread messages</span>
              </p>

              {this.context.state.messages.map((messData) => {
                //
                if (messData.from == "Me") {
                  return <MyMessage message={messData.message} />;
                } else if (messData.from == "You") {
                  return <YourMessage message={messData.message} />;
                }
              })}

              {/* <MyMessage message="kahsvbckjasbckjbas"/> */}
              {/* <YourMessage message="jasbvckjasbvckjavbskcjvaskjchvaskhcvaskjhcvjasvhcjavshc"/> */}
              {/* <Message name="Ananya Chandra" date="20 Nov 2021" profilePicture={ProfilePicture} message="Thanks"/>
                        <Message name="Ananya Chandra" date="20 Nov 2021" profilePicture={ProfilePicture} message="Thanks"/>
                        <Message name="Ananya Chandra" date="20 Nov 2021" profilePicture={ProfilePicture} message="Thanks"/>
                        <Message name="Ananya Chandra" date="20 Nov 2021" profilePicture={ProfilePicture} message="Thanks"/>
                        <Message name="Ananya Chandra" date="20 Nov 2021" profilePicture={ProfilePicture} message="Thanks"/> */}
              <div
                style={{ height: "4px", float: "left", clear: "both" }}
                ref={(el) => {
                  this.messagesEnd = el;
                }}
              ></div>
            </div>
            <div className={styles.inputContainer}>
              <form onSubmit={(e) => this.sendMessage(e)} className={styles.innerInputContainer}>
                <div className={styles.addContainer}>
                  <img height="30px" src={Add} alt="Add" />
                </div>
                <input onChange={(e) => this.change(e)} value={this.state.message} className={styles.inputBox} type="text" placeholder="Aa"></input>
                <div className={styles.imagesContainer}>
                  {/* <img className={styles.greyImage} height="25px" src={Emoji} alt="Emoji" /> */}
                  {/* <img height="25px" src={GIF} alt="GIF" /> */}
                  <img
                    onClick={() => {
                      this.emojiAdder("👍");
                    }}
                    className={styles.greyImage}
                    height="23px"
                    src={Thumbup}
                    alt="Thumbup"
                  />
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

BigBox.contextType = ChatContext;
