import React from "react";

import Modal from "../../../../../components/commons/Modal";
import EditAchievements from "./Modals/EditAchievements";

import styles from "../../../Profile.module.css";
import IconTitle from "../../../../../components/commons/IconTitle";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Add } from "@material-ui/icons";

class AchievementsFeed extends React.Component {
  state = {
    edit: false,

    modalTitle: "",
    id: null,
    upload_img: "",
    name: "",
    issuer: "",
    issue_date: null,
    description: "",

    seeall: false,
    add: false,

    endIdx: 2,

    achContent: [],
  };

  changeEdit() {
    this.setState({ edit: false });
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems:'center'
        }}>
          <IconTitle title={t("profile.achievements")} img="/Image/Chalkmate_Achievement.svg" />
              <div
                  style={{
                    background: "#E5E5E5 0% 0% no-repeat padding-box",
                    borderRadius: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: 'pointer',
                    marginRight: '1.5rem'
                  }}
                  onClick={() => {
                    this.setState({ modalTitle: t("profile.addAchievements"), edit: true, add: true, id: null, upload_img: "", name: "", issuer: "", issue_date: null, description: "" });
                    document.body.style.overflow = "hidden";
                  }}
                >
                <Add color="#E5E5E5" />
            </div>
        </div>

        <br />
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          {this.props.achContent?.length === 0 && <div
          style={{
            padding: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          >
            <p className={styles.edit_info}>{t("profile.addyourAchievements")}.</p>
            <button
              className={styles.btn}
              style={{
                border: "2px solid #ED1E24",
                padding: "12px 20px",
                borderRadius: 40,
                cursor: "pointer",
                background:'linear-gradient(180.00deg, rgb(255, 236, 236) 0.011%,rgb(255, 246, 246) 93.847%)'
              }}
              onClick={() => {
                this.setState({ modalTitle: t("profile.addAchievements"), edit: true, add: true, id: null, upload_img: "", name: "", issuer: "", issue_date: null, description: "" });
                document.body.style.overflow = "hidden";
              }}
            >
              <p
                className={styles.btn_text}
                style={{
                  margin: 0,
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                + {t("profile.addAchievements")}
              </p>
            </button>
          </div>}
          <div
            style={{
              // padding: this.state.expContent.length <= 2 ? 0 : 20,
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              // borderBottom: this.state.expContent.length <= 2 ? 'none' : '1px solid #5555551A'
            }}
          >
            {this.props.achContent.slice(0, this.state.endIdx).map((item, idx) => {
              return (
                <div
                  style={{
                    borderBottom: this.props.achContent.length == 1 || (this.props.achContent.length == 2 && idx == 1) ? "none" : "1px solid #5555551A",
                    paddingBottom: 15,
                    marginTop: idx == 0 ? 0 : 20,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <img src={item.upload_img} height="50px" width="50px" style={{ borderRadius: 40, marginRight: 15 }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <p className={styles.section_title}>
                            {item.name}
                            <br />
                            <span className={styles.followers}>{item.issuer}</span>
                          </p>
                        </div>
                      </div>
                      <p className={styles.followers} style={{ opacity: 0.7, color: "#555555", marginBottom: 6 }}>
                        {item.issue_date}
                      </p>
                      <p className={styles.section_desc}>{item.description}</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/Image/Chalkmate_Edit.svg"
                      style={{
                        height: 20,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          modalTitle: t("profile.EditAchievements"),
                          edit: true,
                          add: false,
                          id: item.id,
                          upload_img: item.upload_img,
                          name: item.name,
                          issuer: item.issuer,
                          issue_date: item.issue_date,
                          description: item.description,
                        });
                        document.body.style.overflow = "hidden";
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {this.props.achContent.length <= 2 ? null : (
            <div
              style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 10 }}
              onClick={() => {
                if (this.state.endIdx > 2) {
                  this.setState({ endIdx: 2 });
                } else {
                  this.setState({ endIdx: this.props.achContent.length, seeall: true });
                }

                if (this.state.seeall) {
                  this.setState({ seeall: false });
                } else {
                  this.setState({ seeall: true });
                }
              }}
            >
              <btn style={{ fontSize: 12, fontWeight: 600, color: "#555555", margin: 10 }}>
                {this.props.achContent.length <= 2 ? null : this.state.seeall ? t("common.collapse") : t("common.seeAll")}
              </btn>
            </div>
          )}
        </div>
        {this.state.edit ? (
          <Modal
            card={
              <EditAchievements
                id={this.state.id}
                modalTitle={this.state.modalTitle}
                upload_img={this.state.upload_img}
                name={this.state.name}
                description={this.state.description}
                issuer={this.state.issuer}
                issue_date={this.state.issue_date}
                add={this.state.add}
                changeEdit={this.changeEdit.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    achContent: state.profile?.achievement || [],
  };
}

export default withTranslation()(connect(mapStateToProps, null)(AchievementsFeed));
