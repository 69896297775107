import axios from "axios";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
import {
  SEND_MESSAGE,
  GET_CHAT_LIST,
  GET_CHAT_MESSAGES,
  GET_SEARCH_RESULT,
  CREATE_CHANNEL,
  UPDATE_CHAT_LIST,
  CLEAR_CHAT_HISTORY,
  DELETE_CHAT_CHANNEL,
  DELETE_SELECTED_MESSAGES,
  UPDATE_CHAT_MESSAGES,
  UPDATE_CHAT_MESSAGE
} from "../constants/actionTypes";
import {
  GET_CHAT_LIST_API,
  GET_CHAT_MESSAGES_API,
  SEND_MESSAGE_API,
  GET_SEARCH_RESULT_API,
  CREATE_CHANNEL_API,
  DELETE_ASSIGNMENT_API,
  DELETE_CHAT_CHANNEL_API,
  CLEAR_CHAT_HISTORY_API,
  DELETE_CHAT_MESSAGE_API
} from "../constants/apis";
import { errorMessageFromResponse } from "../constants/commonFunctions";

export const getChatList = () => {
  const token = store.getState()?.profile?.user?.token;

  const data = new FormData();

  data.append("channel_direct", "direct");

  return async (dispatch) => {
    await axios({
      method: "get",
      url: GET_CHAT_LIST_API,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: data,
    })
      .then((response) => {
        if (
          response.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const list = response?.data?.data;
          dispatch({ type: GET_CHAT_LIST, payload: list });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getChatMessages = (channelID) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "get",
      // url: `${GET_CHAT_MESSAGES_API}?channel_id=${channelID}&count=50`,
      url: `${GET_CHAT_MESSAGES_API}?channel_id=${channelID}&dynamic_limit=500`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          dispatch({ type: GET_CHAT_MESSAGES, payload: response?.data?.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const updateChatMessages = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CHAT_MESSAGES, payload: data })
  };
};

export const sendChatMessage = (channelID, message) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "post",
      url: SEND_MESSAGE_API,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
      data: {
        message: message,
        channel_id: channelID,
      },
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          console.log("theSentMsg", response?.data?.data);
          // dispatch({ type: GET_CHAT_MESSAGES, payload: response?.data?.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getChatSearchResult = (searchQuery) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "get",
      url: `${GET_SEARCH_RESULT_API}?search=${searchQuery}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          // const list = response?.data?.data;
          dispatch({ type: GET_SEARCH_RESULT, payload: response?.data?.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const createChannel = (userID) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "post",
      url: CREATE_CHANNEL_API,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
      data: {
        channel_type: "direct",
        recipients_id: [userID],
      },
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          console.log("createChannelRes", response?.data?.data);
          // dispatch({ type: GET_CHAT_MESSAGES, payload: response?.data?.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const updateChatList = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CHAT_LIST, payload: data });
  };
};

export const clearChatHistory = (channelID) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${CLEAR_CHAT_HISTORY_API}?channel_id=${channelID}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 
        ) {
          dispatch({ type: CLEAR_CHAT_HISTORY, payload: channelID });
          dispatch(handleSuccessMessage(`${i18n.t("liveChat.chatHistoryCleared")}`));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// -------------- deleteHistory ----------------

export const deleteChatHistory = (channelID) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${DELETE_CHAT_CHANNEL_API}${channelID}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 
        ) {
          dispatch({ type: DELETE_CHAT_CHANNEL, payload: channelID });
          dispatch(handleSuccessMessage(`${i18n.t("liveChat.chatHistoryDeleted")}`));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const removeChannel = (channelID) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CHAT_CHANNEL, payload: channelID });
  };
};

export const updateMessage = (message) => {
  const userId = store.getState()?.profile?.user?.user_id;
  
  return async (dispatch) => {
    dispatch({ type: UPDATE_CHAT_MESSAGE, payload: message });
    if(userId === message?.sender){
      dispatch(handleSuccessMessage(`${i18n.t("liveChat.messageUpdated")}`));
    }
  };
};


// ------------------- deleteSelectedMessages --------------

export const deleteSelectedMessages = (messages , isDeleteForEveryone) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${DELETE_CHAT_MESSAGE_API}`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: {
        "dlt_all": isDeleteForEveryone,
        "msg_ids": messages
      }
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 
        ) {
          dispatch({ type: DELETE_SELECTED_MESSAGES, payload: messages });
          dispatch(handleSuccessMessage(`${i18n.t(`${isDeleteForEveryone ? 'liveChat.messageDeletedForEveryone' : 'liveChat.messageDeletedForYou'}`)}`));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};