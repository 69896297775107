import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import languageList from "./languageList";

import {
  Theme,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const LanguageSelection = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOptions, setSelectedOptions] = useState([]);
  // const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions[props.categoryName]);

  const {t} = useTranslation();

  const colortheme = createTheme({
    palette: {
      primary: { main: "#ED1E24" },
    },
  });

  const menuItemStyles = {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontWeight: 400,
    // lineHeight: "24px"
    letterSpacing: "0.024px",
    textTransform: "capitalize",
  };

  // const handleChange = (event) => {
  //   props.handleDropdownChange(props.categoryName, event)
  //   console.log("inside handleChange", event.target.value, typeof event.target.value)
  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectedOptions(
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  const handleChange = (event) => {
    console.log("languageSelection", event);
    props.handleDropdownChange(props.categoryName, event);
  };

  // useEffect(()=> {
  //   props.getSelection(props.categoryName, selectedOptions, selectedOptions?.length)

  // }, [selectedOptions])

  // useEffect(()=> {
  //   setSelectedOptions(props.selectedOptions[props.categoryName])

  // }, [props.selectedOptions[props.categoryName]])

  // console.log("inside checkDropdown", props.options, selectedOptions, isOpen);

  // console.log("selectedOnly", selectedOptions, props.selectedOptions[props.categoryName]);

  // console.log("Mars attack", props.categoryName, props.options, selectedOptions)

  console.log(
    "prop selections",
    props.selectedOptions,
    props.selectedOptions[props.categoryName]
  );

  return (
    <div>
      <ThemeProvider theme={colortheme}>
        <Select
          multiple
          // sx={{
          //   borderRadius: "25px",
          //   height: "40px",
          //   fontFamily: "Montserrat",
          //   fontSize: "14px",
          //   fontWeight: "400",
          //   maxHeight: "50rem",
          // }}
          MenuProps={{
            style: {
              maxHeight: "30rem",
              // boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "0.625remnp",
            },
          }}
          value={props.selectedOptions[props.categoryName]}
          // label={props.categoryName}
          onChange={handleChange}
          renderValue={(selected) =>
            props.isMobile ? (
              `${props.categoryName || ""} ${selected.length || ""}`
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}
              >
                <p>{t("profile.addLang")}</p>
                {
                  <p
                    style={{
                      // padding: '0.25rem',
                      borderRadius: "50%",
                      width: "1.25rem",
                      height: "1.25rem",
                      fontWeight: 400,
                      background: "#fff",
                      color: "#676767",
                      fontSize: "0.875rem",
                      textAlign: "center",
                    }}
                  >
                    {selected.length || ""}
                  </p>
                }
              </div>
            )
          }
          defaultValue={"label"}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          // IconComponent={ExpandMoreIcon}
          sx={{
            borderRadius: "25px",
            height: "2.5rem",
            fontFamily: "Montserrat",
            fontSize: "0.875rem",
            fontWeight: "400",
            width: '100%',
            // color:
              // props.selectedOptions[props.categoryName]?.length > 0
                // ? "#fff"
                // : "#4D4D4D",
            // textTransform: "capitalize",
            // backgroundColor:
              // props.selectedOptions[props.categoryName]?.length > 0
                // ? "#ED1E24"
                // : "#fff",
            "& .MuiSvgIcon-root": {
              // color:
                // props.selectedOptions[props.categoryName]?.length > 0
                  // ? "#fff"
                  // : "#4D4D4D",
              // backgroundColor: props.selectedOptions[props.categoryName]?.length > 0 ? "#4D4D4D"  : "#FFCFD0",
              // borderRadius: "50px",
              // "@media (max-width:580px)":{
              //   top: "calc(50% - 0.34em)"
              // }
            },
            "@media (max-width:580px)": {
              height: "1.5rem",
              fontSize: "0.875rem",
              fontWeight: "500",
              lineHeight: "1.5",
              // width: "100px",
            },
          }}
        >
          <MenuItem sx={menuItemStyles} disabled value="label">
            <em>{props.categoryName}</em>
          </MenuItem>

          {languageList?.map((item, index) => (
            <MenuItem key={item?.code} value={item?.code}>
              <Checkbox
                checked={
                  props.selectedOptions[props.categoryName]?.indexOf(
                    item?.code
                  ) > -1
                }
              />
              {/* <Checkbox checked={selectedOptions?.includes(item?.code)} /> */}
              <ListItemText primary={item?.name} />
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>
    </div>
  );
};

export default LanguageSelection;
