import React, { useState } from "react";
import styles from "./AddLanguageModal.module.css";
import { ModalWithHeader } from "../../../../Dashboard/shared/ui/Modal/Modal";
import { useTranslation } from "react-i18next";
import ButtonMUI from "@mui/material/Button";
import LanguageSelection from "../../../../../components/commons/LanguageSelection";
import { produce } from "immer";
import languageList from "../../../../../components/commons/languageList";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileData } from "../../../../../redux/actions/profile";

const AddLanguageModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const getLanguageName = (code) => {
    let index = languageList?.findIndex((x) => x.code === code);
    if (index >= 0) {
      return languageList[index]?.name;
    } else return "";
  };

  const getLanguageCode = (lang) => {
    let index = languageList?.findIndex((x) => x.name === lang);
    if (index >= 0) {
      return languageList[index]?.code;
    } else return "";
  };


  const knownLanguages = useSelector((state)=>state?.profile?.language);
  const knownCodes = knownLanguages?.split(', ')?.map((x)=>getLanguageCode(x))?.filter(z => Boolean(z));

  const [selectedOptions, setSelectedOptions] = useState({
    languages: knownCodes,
  });

  let colors = [
    "#cef4f7",
    "#d1cdec",
    "#fed3da",
    "#fef0d6",
    "#c5ebd4",
    "#c5deeb",
    "#fed6f9",
  ];

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "20px",
      padding: "5px 11px 6px 11px",
    },
  };

  const handleSave = () => {
    let payload = new FormData();

    let codes = selectedOptions["languages"]?.join(", ");
    let langNames = selectedOptions["languages"]
      ?.map((x) => getLanguageName(x))
      ?.join(", ");

    payload.append("language_known", langNames);
    payload.append("language_code", codes);

    console.log("theLangPayload", { codes, langNames });

    dispatch(updateProfileData("EDIT_PROFILE_DATA", payload)).then(() =>
      props.onClose()
    );
  };

  const handleDropdownChange = (category, event) => {
    setSelectedOptions(
      produce(selectedOptions, (draft) => {
        draft[category] = [...event.target.value];
      })
    );
  };

  const removeLanguage = (lang) => {
    setSelectedOptions(
      produce(selectedOptions, (draft) => {
        draft["languages"] = draft["languages"]?.filter((x)=> x != lang);
      })
    );
  };



  

  console.log("languageModal", selectedOptions, selectedOptions["languages"]);
  console.log({knownLanguages, knownCodes});

  return (
    <ModalWithHeader
      title={t("profile.editLanguages")}
      show={props.open}
      closeHandler={props.onClose}
      maxWidth="28rem"
      minWidth="21rem"
    >
      <div className={styles.middle_container}>
        <div>
          <LanguageSelection
            selectedOptions={selectedOptions}
            categoryName={"languages"}
            handleDropdownChange={handleDropdownChange}
          />
        </div>

        {/* tags */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 5,
            marginTop: '1.25rem',
            marginBottom: '2.5rem'
          }}
        >
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            {selectedOptions["languages"]?.length > 0
              ? selectedOptions["languages"]?.map((item, idx) => (
                  <li
                    key={item}
                    className={styles.tagList}
                    style={{
                      background: `${colors[idx % colors.length]}`,
                    }}
                    onClick={()=>removeLanguage(item)}
                  >
                    {getLanguageName(item)}
                    <img src="/Image/DashboardImages/postModImages/cross.svg" alt="X" style={{marginLeft: '0.5rem', width:'1.25rem'}} />
                  </li>
                ))
              : ""}
          </ul>
        </div>

        {/* buttons */}

        <div className={styles.bottom_container}>
          <p onClick={props.onClose} className={styles.btn_label_cancel}>
            {t("common.cancel")}
          </p>

          <ButtonMUI
            variant="outlined"
            disableRipple
            onClick={handleSave}
            sx={styleButtonMUI}
          >
            <span className={styles.btn_label}>{t("common.save")}</span>
          </ButtonMUI>
        </div>
      </div>
    </ModalWithHeader>
  );
};

export default AddLanguageModal;
